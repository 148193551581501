import { useState, FC, useEffect } from 'react';
import Logo from '../../images/touchpix-logo.png';
import I18n from '../../helpers/i18n';
import { useParams, useNavigate } from 'react-router-dom';
import Helpers from '../../helpers/functions';
import useDebounce from '../../hooks/use-debounce';
import * as Sentry from '@sentry/react';
import DirectusManager from '../../helpers/directusManager';
import Input from '../../components/input';
import Button from '../../components/button';

const Validators = Helpers.instance;

interface Props {}

const NewPassword: FC<Props> = (props) => {
    const { token } = useParams<{ token: string }>();
    const [loader, setLoader] = useState(false);
    const [loaderForm, setLoaderForm] = useState(false);

    const navigate = useNavigate();

    const [disableForm, setDisableForm] = useState(true);
    const [errorUpdateForm, setErrorUpdateForm] = useState(false);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');

    const [newPassword, setNewPassword] = useState('');
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [newPasswordErrorText, setNewPasswordErrorText] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState('');

    const debouncedAccountTerm = useDebounce(email, 400);
    useEffect(() => {
        if (email !== '') {
            let valField = Validators.validateEmail1(email);
            setEmailError(valField[0]);
            setEmailErrorText(valField[1]);
        }
    }, [debouncedAccountTerm]);

    const debouncedNewPasswordTerm = useDebounce(newPassword, 400);
    useEffect(() => {
        if (newPassword !== '') {
            let valField = Validators.validateCreateNewPassword(newPassword);
            setNewPasswordError(valField[0]);
            setNewPasswordErrorText(valField[1]);
        }
    }, [debouncedNewPasswordTerm]);

    const debouncedConfirmPasswordTerm = useDebounce(confirmPassword, 400);
    useEffect(() => {
        if (confirmPassword !== '') {
            let valField = Validators.validateConfirmNewPassword(newPassword, confirmPassword);
            setConfirmPasswordError(valField[0]);
            setConfirmPasswordErrorText(valField[1]);
        }
    }, [debouncedConfirmPasswordTerm]);

    const checkIfFormIsAllowed = () => {
        if (
            email !== '' &&
            !emailError &&
            newPassword !== '' &&
            !newPasswordError &&
            confirmPassword !== '' &&
            !confirmPasswordError
        ) {
            setDisableForm(false);
        } else {
            setDisableForm(true);
        }
    };
    useEffect(() => {
        checkIfFormIsAllowed();
    }, [email, emailError, newPassword, newPasswordError, confirmPassword, confirmPasswordError]);

    const handleSubmit = () => {
        const valEmail = Validators.validateString(email);
        const valPassword = Validators.validateCreateNewPassword(newPassword);
        const valConfirmPassword = Validators.validateConfirmNewPassword(newPassword, confirmPassword);
        if (!valEmail[0]) {
            setEmailError(valEmail[0]);
            setEmailErrorText(valEmail[1]);
        }
        if (!valPassword[0]) {
            setNewPasswordError(valPassword[0]);
            setNewPasswordErrorText(valPassword[1]);
        }
        if (!valConfirmPassword[0]) {
            setNewPasswordError(valConfirmPassword[0]);
            setNewPasswordErrorText(valConfirmPassword[1]);
        } else {
            return;
        }
        sendForm();
    };

    const sendForm = () => {
        setLoaderForm(true);
        setErrorUpdateForm(false);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: email,
                password: newPassword,
                token: token,
            }),
        };

        fetch(DirectusManager.shared.baseUrl + '/password-reset/reset', requestOptions)
            .then((res) => {
                if (res.status === 200) {
                    navigate('/login');
                } else {
                    setLoaderForm(false);
                    setErrorUpdateForm(true);
                }
            })
            .catch((err) => {
                Sentry.captureException(err);
                console.log('err', err);
                setLoaderForm(false);
                setErrorUpdateForm(true);
            });
    };

    return (
        <section className='login d-flex justify-content-center align-items-center'>
            {loader ? (
                <div className='emptystate' />
            ) : (
                <div className='login-block text-align-center'>
                    <div className='logo mt-3 mb-5'>
                        <img className='logo' src={Logo} alt='logo Touchpix' />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <Input
                            value={email}
                            onChange={(val) => setEmail(val)}
                            label={I18n.t('FORM_EMAIL')}
                            email
                            error={emailError}
                            errorText={emailErrorText}
                        />
                        <div className='mt-3' />
                        <Input
                            value={newPassword}
                            label={I18n.t('FORM_NEW_PASSWORD_FORGOT')}
                            secure
                            onChange={(val) => setNewPassword(val)}
                            error={newPasswordError}
                            errorText={newPasswordErrorText}
                        />
                        <div className='mt-3' />
                        <Input
                            value={confirmPassword}
                            label={I18n.t('FORM_CONFIRM_PASSWORD')}
                            secure
                            onChange={(val) => setConfirmPassword(val)}
                            error={confirmPasswordError}
                            errorText={confirmPasswordErrorText}
                        />
                        <div className='mt-3' />
                        <Button
                            title={I18n.t('SUBMIT')}
                            defaultStyle
                            fullwidth
                            disabled={disableForm}
                            onPress={() => handleSubmit()}
                        />
                    </form>
                    {errorUpdateForm && <div className='error-send-form'>{I18n.t('FORM_SEND_ERROR')}</div>}
                </div>
            )}
        </section>
    );
};
export default NewPassword;

import React, { FC, useEffect, useState } from 'react';
import I18n from '../../helpers/i18n';
import Chart from './chart';

interface Props {
    title: string;
    data: {
        totalReferrals: number;
        totalConfirmed: number;
        totalRenewal: number;
        totalPending: number;
        totalDone: number;
        profit: number;
        rate: number;
        totalReferralsM: number;
        totalReferralsValidatedM: number;
        profitM: number;
        rateM: number;
    };
    downloadButton: any;
}

const Statics: FC<Props> = (props) => {
    const getProps = (type: any) => {
        if (type === 'totalReferrals') {
            if (props.data.totalReferrals == Number.NaN || null || undefined) {
                return '0';
            } else {
                return props.data.totalReferrals;
            }
        }

        if (type === 'totalReferralsValidated') {
            if (props.data.totalConfirmed == Number.NaN || null || undefined) {
                return '0';
            } else {
                return props.data.totalConfirmed;
            }
        }

        if (type === 'profit') {
            if (isNaN(props.data.profit)) {
                return '$0';
            } else {
                return '$' + props.data.profit.toFixed(2);
            }
        }

        if (type === 'totalReferralsM') {
            if (props.data.totalReferralsM == Number.NaN || null || undefined) {
                return '0';
            } else {
                return props.data.totalReferralsM;
            }
        }

        if (type === 'totalReferralsValidatedM') {
            if (props.data.totalReferralsValidatedM == Number.NaN || null || undefined) {
                return '0';
            } else {
                return props.data.totalReferralsValidatedM;
            }
        }

        if (type === 'profitM') {
            if (isNaN(props.data.profitM)) {
                return '$0';
            } else {
                return '$' + props.data.profitM.toFixed(2);
            }
        }

        return;
    };

    const chartDataTotal = {
        labels: [I18n.t('CHART_PENDING'), I18n.t('CHART_CREATED'), I18n.t('CHART_RECEIVED'), I18n.t('CHART_RENEWAL')],
        datasets: [
            {
                data: [
                    props.data.totalPending,
                    props.data.totalDone,
                    props.data.totalConfirmed,
                    props.data.totalRenewal,
                ],
                backgroundColor: ['#5ac2cf', '#4e7795', '#de994e', '#e56366', '#f7f7f7'],
            },
        ],
    };

    /*
        In case we want display a pie chart with the data by months, we have to return 
        the correct data from the endpoint and change the values here
    */
    const chartDataMonthly = {
        labels: ['Total', I18n.t('STATUS_VALIDATED')],
        datasets: [
            {
                data: [props.data.totalReferralsM, props.data.totalReferralsValidatedM],
                backgroundColor: ['#5ac2cf', '#4e7795'],
            },
        ],
    };

    const getData = (type: string) => {
        if (type === 'chartDataTotal') {
            chartDataTotal.datasets.forEach((dataset) => {
                if (dataset.data.every((el: any) => el === 0)) {
                    dataset.backgroundColor.push('rgba(255,255,255,1)');
                    dataset.data.push(1);
                }
            });
            return chartDataTotal;
        } else if (type === 'chartDataMonthly') {
            chartDataMonthly.datasets.forEach((dataset) => {
                if (dataset.data.every((el: any) => el === 0)) {
                    dataset.backgroundColor.push('rgba(255,255,255,1)');
                    dataset.data.push(1);
                }
            });
            return chartDataMonthly;
        }
        return false;
    };

    return (
        <div className="home-dashboard">
            <div className="d-flex justify-content-between align-items-center header">
                <h3 className="title">{props.title}</h3>
                {props.downloadButton}
            </div>
            <div className="container-fluid d-flex justify-content-between align-items-center p-0 m-0">
                <div className="row w-100 p-0 m-0">
                    {/* <div className="chart-child">
                    <h5 className="sub-title">{I18n.t('MONTHLY_RATE')}</h5>
                    <hr />
                    <Chart data={getData('chartDataMonthly')} />
                </div> */}
                    <div className="col-12 col-lg-6 mb-4">
                        <div className="statistics-block">
                            <h5 className="sub-title">{I18n.t('TOTAL_RATE')}</h5>
                            <hr />
                            <Chart data={getData('chartDataTotal')} />
                        </div>
                    </div>

                    <div className="col-12 col-lg-6 mb-4">
                        <div className="statistics-block">
                            <div className="sub-child">
                                <h5 className="sub-title">{I18n.t('TOTAL_PROFIT_MONTHLY')}</h5>
                                <hr />
                                <div className="amount-text">{getProps('profitM')}</div>
                            </div>
                            <div className="sub-child">
                                <h5 className="sub-title">{I18n.t('TOTAL_PROFIT')}</h5>
                                <hr />
                                <div className="amount-text">{getProps('profit')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 mb-4">
                        <div className="statistics-block">
                            <div className="sub-child">
                                <h5 className="sub-title">{I18n.t('TOTAL_MONTHLY_REFERRALS')}</h5>
                                <hr />
                                <div className="amount-text">{getProps('totalReferralsM')}</div>
                            </div>

                            <div className="sub-child">
                                <h5 className="sub-title">{I18n.t('TOTAL_REFERRALS')}</h5>
                                <hr />
                                <div className="amount-text">{getProps('totalReferrals')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 mb-4">
                        <div className="statistics-block">
                            <div className="sub-child">
                                <h5 className="sub-title">{I18n.t('TOTAL_VALIDATED_REFERRALS_MONTHLY')}</h5>
                                <hr />
                                <div className="amount-text">{getProps('totalReferralsValidatedM')}</div>
                            </div>
                            <div className="sub-child">
                                <h5 className="sub-title">{I18n.t('TOTAL_VALIDATED_REFERRALS')}</h5>
                                <hr />
                                <div className="amount-text">{getProps('totalReferralsValidated')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Statics;

import { FC, useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
    data: any;
}
const Chart: FC<Props> = (props) => {
    const [loading, setLoading] = useState(true);
    const [displayChart, setDisplayChart] = useState(false);

    useEffect(() => {
        chartDataCheck();
    }, [props.data]);

    const chartDataCheck = () => {
        if (
            props.data &&
            props.data.datasets &&
            props.data.datasets[0] &&
            props.data.datasets[0].data &&
            props.data.labels &&
            props.data.labels.length > 0
        ) {
            let counter = 0;
            for (var i = 0; i < props.data.labels.length; i++) {
                let item = props.data.datasets[0].data[i];
                if (parseInt(item) > 0) {
                    counter = counter + parseInt(item);
                    if (counter > 0) {
                        setDisplayChart(true);
                    }
                }
            }
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    return (
        <div className="chart-static">
            {displayChart ? (
                <Doughnut
                    data={props.data}
                    options={{
                        responsive: true,
                        layout: {
                            padding: {
                                bottom: 20,
                                top: 10,
                            },
                        },
                        plugins: {
                            legend: {
                                display: true,
                                position: 'bottom',
                                title: {
                                    display: true,
                                    padding: 10,
                                },
                            },
                            title: {
                                display: true,
                                text: 'Total validatation rate',
                            },
                        },
                    }}
                />
            ) : (
                !loading && <span className="empty-state">No data yet</span>
            )}
        </div>
    );
};

export default Chart;

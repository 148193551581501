import React, { FC, useEffect } from 'react';
import Sidebar from '../sidebar';
import Footer from '../footer';
import PageHeader from '../page-header';
import I18n from '../../helpers/i18n';
interface Props {
    title?: string;
    public: boolean;
}

const Wrapper: FC<Props> = (props) => {
    useEffect(() => {
        document.title = I18n.t('SITE_NAME');
    }, []);

    return (
        <div>
            {!props.public ? (
                <div className="d-flex" id="main">
                    <Sidebar />
                    <div id="content-wrapper">
                        <PageHeader title={props.title} public={props.public} />
                        {props.children}
                    </div>
                </div>
            ) : (
                <div>{props.children}</div>
            )}
            <Footer />
        </div>
    );
};
export default Wrapper;

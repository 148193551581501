import React, { useState, FC, useEffect, useRef } from 'react';
import I18n from '../../helpers/i18n';
import Input from '../../components/input';
import Button from '../../components/button';
import Logo from '../../images/touchpix-logo.png';
import { useNavigate } from 'react-router-dom';
import DirectusManager from '../../helpers/directusManager';
import Snackbar from '../../components/snackbar';
import { useParams } from 'react-router';
import * as Sentry from '@sentry/react';
interface Props {}

const ResetPassword: FC<Props> = (props) => {
    const navigate = useNavigate();

    const { token } = useParams<{ token: string }>();

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorText, setPasswordErrorText] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState('');

    const snackBar = useRef();

    const checkFields = () => {
        setPasswordError(false);
        setPasswordErrorText('');
        setConfirmPasswordError(false);
        setConfirmPasswordErrorText('');
        setEmailError(false);
        setEmailErrorText('');

        if (password != confirmPassword) {
            setConfirmPasswordError(true);
            setConfirmPasswordErrorText(I18n.t('PASSWORD_DONT_MATCH'));
            return;
        }

        //SET NEW PASSWORD API CALL AND RETURN TO MAIN
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email, token: token, password: password }),
        };

        fetch(DirectusManager.shared.baseUrl + '/password-reset/reset', requestOptions)
            .then((response) => {
                if (response.status === 200) {
                    navigate('/');
                }
            })
            .catch((error) => {
                console.log(error);
                Sentry.captureException(error);
            });
    };

    const navigateBack = () => {
        navigate('/login');
    };

    return (
        <div>
            <section className='login d-flex justify-content-center align-items-center'>
                <div className='login-block text-align-center'>
                    <div className='mt-3 mb-5'>
                        <img src={Logo} className='logo' alt='logo DrLeenarts' />
                    </div>
                    <Input
                        label={I18n.t('FORM_EMAIL')}
                        value={email}
                        error={emailError}
                        errorText={emailErrorText}
                        onChange={(val: any) => setEmail(val)}
                    />
                    {emailError && <span className='error-field'>{emailErrorText}</span>}
                    <div className='mt-3' />
                    <Input
                        label={I18n.t('FORM_PASSWORD')}
                        value={password}
                        error={passwordError}
                        errorText={passwordErrorText}
                        onChange={(val: any) => setPassword(val)}
                        secure
                    />
                    {passwordError && <span className='error-field'>{passwordErrorText}</span>}
                    <div className='mt-3' />
                    <Input
                        label={I18n.t('FORM_PASSWORD_CONFIRM')}
                        value={confirmPassword}
                        error={confirmPasswordError}
                        errorText={confirmPasswordErrorText}
                        onChange={(val: any) => setConfirmPassword(val)}
                        secure
                    />
                    {passwordError && <span className='error-field'>{passwordErrorText}</span>}
                    <div className='mt-3' />
                    <Button defaultStyle fullwidth title={I18n.t('RESETPASSWORD_SET')} onPress={() => checkFields()} />
                    <div className='mt-3' />
                    <Button defaultStyle fullwidth title={I18n.t('BACK')} onPress={() => navigateBack()} />
                </div>
            </section>
            <Snackbar ref={snackBar} noSidebar />
        </div>
    );
};

export default ResetPassword;

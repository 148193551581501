import { FC, useContext } from 'react';
import I18n from '../../helpers/i18n';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../images/touchpix-logo.png';
import { UserContext } from '../../context/userContext';

//menu icons
import HomeIcon from '@mui/icons-material/Home';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PeopleIcon from '@mui/icons-material/People';
import EmailIcon from '@mui/icons-material/Email';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

interface Props {}

const SideBar: FC<Props> = (props) => {
    const location = useLocation();
    const userContext = useContext(UserContext);

    const menuArray = [
        {
            name: I18n.t('NAV_HOME'),
            path: '/',
            icon: <HomeIcon />,
            onlyAdmin: false,
        },
        {
            name: I18n.t('CUSTOMERS'),
            path: '/customers',
            icon: <PeopleIcon />,
            onlyAdmin: false,
        },
        {
            name: I18n.t('REFERRALS'),
            path: '/referrals',
            icon: <MonetizationOnIcon />,
            onlyAdmin: false,
        },
        {
            name: I18n.t('EMAIL_TEMPLATE'),
            path: '/email-template',
            icon: <EmailIcon />,
            onlyAdmin: false,
        },
        {
            name: I18n.t('PROFIT_LIST'),
            path: '/profit-list',
            icon: <FormatListBulletedIcon />,
            onlyAdmin: false,
        },
    ];

    const isActiveSubPath = (path: string, subpath: string) => {
        var activePath = false;

        var pathArray = window.location.pathname.split('/');
        var detailPathMain = '/' + pathArray[pathArray.length - 2];

        let cleanPath = location.pathname;

        if (location.pathname.split('/').length === 4) {
            let normalized = location.pathname.split('/');
            normalized.pop();
            cleanPath = normalized.join('/');
            detailPathMain = '/' + pathArray[pathArray.length - 3];
        }
        if (location.pathname.split('/').length === 5) {
            let normalized = location.pathname.split('/');
            normalized.pop();
            cleanPath = normalized.join('/');
            detailPathMain = '/' + pathArray[pathArray.length - 4] + '/' + pathArray[pathArray.length - 3];
        }

        if (subpath == cleanPath || (pathArray.length > 2 && detailPathMain == subpath)) {
            activePath = true;
        }

        return activePath;
    };

    const isActiveMainPath = (path: string) => {
        var activePath = false;

        var pathArray = window.location.pathname.split('/');
        var subPathMain = '/' + pathArray[pathArray.length - 2];
        var detailPathMain = '/' + pathArray[pathArray.length - 3];
        var subDetailPathMain = '/' + pathArray[pathArray.length - 4];

        if (
            path == location.pathname ||
            (pathArray.length > 2 && subPathMain == path) ||
            (pathArray.length > 3 && detailPathMain == path) ||
            (pathArray.length > 4 && subDetailPathMain == path)
        ) {
            activePath = true;
        }

        return activePath;
    };

    const showSubmenu = (path: string) => {
        var pathArray = window.location.pathname.split('/');
        var submenuPath = pathArray[pathArray.length - 1];
        let cleanPath = location.pathname;

        if (location.pathname.split('/').length === 4) {
            let normalized = location.pathname.split('/');
            normalized.pop();
            cleanPath = normalized.join('/');
            submenuPath = pathArray[pathArray.length - 2];
        }

        if (location.pathname.split('/').length === 5) {
            let normalized = location.pathname.split('/');
            normalized.pop();
            cleanPath = normalized.join('/');
            submenuPath = pathArray[pathArray.length - 3] + '/' + pathArray[pathArray.length - 2];
        }
        if (cleanPath == path || cleanPath == path + '/' + submenuPath) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div id="sidebar">
            <div className="logo">
                <img className="logo" src={Logo} alt="logo Touchpix" />
            </div>
            <ul>
                {userContext !== null &&
                    menuArray.length > 0 &&
                    menuArray.map((item: any, index: any) => {
                        if (
                            !item.onlyAdmin ||
                            (item.onlyAdmin && userContext.role === '620dc560-c18e-4df1-86b2-99201c69200e')
                        ) {
                            return (
                                <li className={isActiveMainPath(item.path) ? 'active' : ''} key={index}>
                                    <div className="d-flex align-items-center item">
                                        <Link to={{ pathname: item.path }}>
                                            <div className={isActiveMainPath(item.path) ? 'icon active' : 'icon'}>
                                                {item.icon}
                                            </div>
                                            {item.name}
                                        </Link>
                                    </div>
                                    {showSubmenu(item.path) && (
                                        <ul className="submenu">
                                            {item.submenu &&
                                                item.submenu.length > 0 &&
                                                item.submenu.map((subitem: any, index: any) => {
                                                    return (
                                                        <li
                                                            className={
                                                                isActiveSubPath(item.path, subitem.path) ? 'active' : ''
                                                            }
                                                            key={index}
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                <Link to={{ pathname: subitem.path }}>
                                                                    <div
                                                                        className={
                                                                            isActiveSubPath(item.path, subitem.path)
                                                                                ? 'icon active'
                                                                                : 'icon'
                                                                        }
                                                                    >
                                                                        {subitem.icon}
                                                                    </div>
                                                                    {subitem.name}
                                                                </Link>
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                        </ul>
                                    )}
                                </li>
                            );
                        }
                    })}
            </ul>
        </div>
    );
};
export default SideBar;

import { Directus } from '@directus/sdk';

export default class DirectusManager {
    static shared: DirectusManager = new DirectusManager();

    production = true;

    baseUrl = this.production ? 'https://referraltp.tecdam.io' : 'https://referraltp-dev.tecdam.io';
    directus = new Directus(this.baseUrl, {
        auth: { mode: 'json', autoRefresh: true, msRefreshBeforeExpires: 3_600_000 },
        storage: {
            mode: 'LocalStorage',
        },
    });

    login(credentials: any) {
        return this.directus.auth.login(credentials);
    }

    logout() {
        return this.directus.auth.logout();
    }

    isLoggedIn() {
        return this.directus.auth.token != null;
    }

    readUserMe() {
        return this.directus.users.me.read();
    }
}

import axios from 'axios';
import React, { useState, FC, useEffect, useContext } from 'react';
import Wrapper from '../../components/wrapper';
import DirectusManager from '../../helpers/directusManager';
import { useNavigate } from 'react-router-dom';
import I18n from '../../helpers/i18n';
import Statics from '../../components/statics';
import Button from '../../components/button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { UserContext } from '../../context/userContext';
import * as Sentry from '@sentry/react';

const HomePage: FC = (props) => {
    const navigate = useNavigate();
    const userContext = useContext(UserContext);
    const [loaderButton, setLoaderButton] = useState(false);

    const [totalProfit, setTotalProfit] = useState(0);
    const [totalMonthlyProfit, setTotalMonthlyProfit] = useState(0);
    const [totalReferrals, setTotalReferrals] = useState(0);
    const [totalConfirmed, setTotalConfirmed] = useState(0);
    const [totalRenewal, setTotalRenewal] = useState(0);
    const [totalPending, setTotalPending] = useState(0);
    const [totalDone, setTotalDone] = useState(0);
    const [totalMonthlyReferrals, setTotalMonthlyReferrals] = useState(0);
    const [totalValidatedMonthly, setTotalValidatedMonthly] = useState(0);
    const [commission, setCommission] = useState(0);

    const [data, setData] = useState({
        totalReferrals: 0,
        totalConfirmed: 0,
        totalRenewal: 0,
        totalPending: 0,
        totalDone: 0,
        profit: 0,
        rate: 0,
        totalReferralsM: 0,
        totalReferralsValidatedM: 0,
        profitM: 0,
        rateM: 0,
    });

    useEffect(() => {
        if (DirectusManager.shared.isLoggedIn() == false) {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        getData();
        getSupplierCommission();
    }, []);

    useEffect(() => {
        processData();
    }, [
        totalReferrals,
        totalConfirmed,
        totalPending,
        totalDone,
        totalMonthlyReferrals,
        totalValidatedMonthly,
        commission,
    ]);

    const getSupplierCommission = async () => {
        let supplier_id = userContext.supplier_id;
        let s = DirectusManager.shared.directus.items('suppliers');
        s.readOne(supplier_id, { fields: ['*.*'] })
            .then((s) => {
                setCommission(s.commission);
            })
            .catch((error) => {
                Sentry.captureException(error);
                console.log(error.message);
            });
    };

    const getData = async () => {
        let supplier_id = userContext.supplier_id;
        const token = await DirectusManager.shared.directus.auth.token;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                auth_token: DirectusManager.shared.directus.auth.token,
                supplier_id: supplier_id,
            }),
        };

        fetch(DirectusManager.shared.baseUrl + '/referral-data', requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if (response != undefined) {
                    setTotalProfit(response.total_profit);
                    setTotalMonthlyProfit(response.monthly_profit);
                    setTotalReferrals(response.counter_total_referrals);
                    setTotalConfirmed(response.counter_total_confirmed);
                    setTotalRenewal(response.counter_total_renewal);
                    setTotalDone(response.counter_total_done);
                    setTotalPending(response.counter_total_pending);
                    setTotalMonthlyReferrals(response.counter_total_month);
                    setTotalValidatedMonthly(response.counter_total_confirmed_monthly);
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                console.log(error);
            });
    };

    const processData = () => {
        if (commission !== 0) {
            setData({
                totalReferrals: totalReferrals,
                totalConfirmed: totalConfirmed,
                totalRenewal: totalRenewal,
                totalPending: totalPending,
                totalDone: totalDone,
                profit: totalProfit,
                rate: Math.round((totalConfirmed / totalReferrals) * 100),
                totalReferralsM: totalMonthlyReferrals,
                totalReferralsValidatedM: totalValidatedMonthly,
                profitM: totalMonthlyProfit,
                rateM: Math.round((totalValidatedMonthly / totalMonthlyReferrals) * 100),
            });
        }
    };

    const exportStatics = async () => {
        setLoaderButton(true);
        let supplier_id = userContext.supplier_id;
        let token = await DirectusManager.shared.directus.auth.token;
        var data = JSON.stringify({
            supplier_id: supplier_id.toString(),
            auth_token: token,
        });

        var config = {
            method: 'post',
            url: DirectusManager.shared.baseUrl + '/export-supplier-statics/',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            data: data,
        };

        axios(config)
            .then(function (response: any) {
                window.open(response.data.data.url + '?download=&access_token=' + token);
            })
            .catch(function (error) {
                Sentry.captureException(error);
                console.log(error);
            })
            .finally(() => {
                setLoaderButton(false);
            });
    };

    const handleOnPress = async () => {
        await exportStatics();
    };

    return (
        <Wrapper title={I18n.t('HOME_DASHBOARD')} public={false}>
            <div className="dashboard-wrapper">
                <Statics
                    title={I18n.t('STATICS')}
                    data={data}
                    downloadButton={
                        <Button
                            defaultStyle
                            title={I18n.t('EXPORT_STATICS')}
                            icon={<FileDownloadIcon />}
                            onPress={() => handleOnPress()}
                            loader={loaderButton}
                        />
                    }
                />
            </div>
        </Wrapper>
    );
};

export default HomePage;

import axios from 'axios';
import { useState, FC, useEffect, useRef, useContext } from 'react';
import { UserContext } from '../../context/userContext';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import I18n from '../../helpers/i18n';
import Helpers from '../../helpers/functions';
import DirectusManager from '../../helpers/directusManager';
import Wrapper from '../../components/wrapper';
import Snackbar from '../../components/snackbar';
import Table from '../../components/tables/overview-table-full';

const Referrals: FC = (props) => {
    const Validators = Helpers.instance;
    const userContext = useContext(UserContext);
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState<any>([]);
    const [pageCount, setPageCount] = useState<number>(1);
    const snackBar = useRef();
    const [activeFilter, setActiveFilter] = useState<any>(null);
    const [activeQuery, setActiveQuery] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loaderButtonExport, setLoaderButtonExport] = useState(false);

    const location = useLocation() as any;
    const navigate = useNavigate() as any;

    useEffect(() => {
        getData();

        if (location?.state == 'new') {
            navigate('', { state: [] });
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('NEW_REFERRAL_ADDED'));
        }
    }, []);

    const getCstData = async (id: any) => {
        const a = await getCustomerData(id);
        return a;
    };

    const getCustomerData = async (id: number) => {
        if (id == undefined) return;
        var referrals = await DirectusManager.shared.directus.items('customers');
        const response = await referrals
            .readOne(id)
            .then((cst) => {
                if (cst !== undefined && cst !== null) {
                    return cst;
                }
            })
            .catch((error) => {
                console.log('Error: ', error);
                return false;
            });

        return response;
    };

    const getData = (filters?: any, query?: string, page?: number) => {
        var filter: any = { deleted: false };
        var sort: any = ['-date_created'];
        var fields: any = [
            'id',
            'code',
            'status',
            'supplier_id.user_id',
            'customer_id',
            'date_created',
            'date_updated',
            'user_created',
            'expiry_date',
        ];

        if (filters) {
            filter = filters;
            filter['deleted'] = false;
        }

        if (query) {
            filter = Validators.searchStringFilter('code', query);
            filter['deleted'] = false;
        }

        var referrals = DirectusManager.shared.directus.items('referrals');
        referrals
            .readByQuery({ fields: fields, meta: 'filter_count', filter, sort: sort, limit: 20, page: page ?? 1 })
            .then(async (refs) => {
                var logged_user_id = userContext.user_id;
                if (
                    refs !== undefined &&
                    refs.data !== undefined &&
                    refs.data !== null &&
                    logged_user_id !== undefined
                ) {
                    const x = refs.data.filter((x: any) => x.supplier_id.user_id === logged_user_id);
                    const promises = x.map((y) => getCstData(y.customer_id));
                    const resolve = await Promise.all(promises);
                    const mapped = x.map((y) => {
                        const name: any = resolve.find((z: any) => z.id === y.customer_id);
                        return {
                            id: y.id,
                            col0: y.code,
                            col1: getStatus(y.status),
                            col2: name ? name.name : '',
                            col3: y.expiry_date ? moment(y.expiry_date).format('DD-MM-YYYY') : '-',
                            col4: y.date_updated ? moment(y.date_updated).format('DD-MM-YYYY') : '-',
                            col5: y.date_created ? moment(y.date_created).format('DD-MM-YYYY') : '-',
                        };
                    });

                    setData(mapped);

                    if (refs.meta?.filter_count !== undefined) {
                        var totalPages = Math.ceil(refs.meta?.filter_count / 20);
                        setPageCount(totalPages);
                    }
                }
            })
            .catch((error) => {
                console.log(error.message);
            });
    };

    const filterOn = (type: string, value: any) => {};

    const confirmDelete = (id: number) => {
        var referrals = DirectusManager.shared.directus.items('referrals');
        referrals
            .updateOne(id, { deleted: true })
            .then((data) => {
                setLoader(false);
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('ITEM_DELETED'));
                getData();
            })
            .catch((error: any) => {
                setLoader(false);
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
            });
    };

    const filterText = (value: any) => {
        setActiveQuery(value);
        if (activeFilter) {
            getData(activeFilter, value);
        } else {
            getData(null, value);
        }
    };

    const changePage = (page: number) => {
        getData(activeFilter, activeQuery, page + 1);
        setCurrentPage(page + 1);
    };

    const exportReferrals = async () => {
        setLoaderButtonExport(true);
        let supplier_id = userContext.supplier_id;
        let token = await DirectusManager.shared.directus.auth.token;
        var data = JSON.stringify({
            supplier_id: supplier_id.toString(),
            auth_token: token,
        });

        var config = {
            method: 'post',
            url: DirectusManager.shared.baseUrl + '/export-supplier-referrals/',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            data: data,
        };

        axios(config)
            .then(function (response: any) {
                window.open(response.data.data.url + '?download=&access_token=' + token);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(() => {
                setLoaderButtonExport(false);
            });
    };

    const handleOnPress = async () => {
        await exportReferrals();
    };

    const getStatus = (status: string) => {
        let st = '';
        if (status === 'pending') {
            st = I18n.t('STATUS_PENDING');
        }
        if (status === 'done') {
            st = I18n.t('STATUS_DONE');
        }
        if (status === 'confirmed') {
            st = I18n.t('STATUS_CONFIRMED');
        }
        if (status === 'renewal') {
            st = I18n.t('STATUS_RENEWAL');
        }
        return st;
    };

    return (
        <Wrapper title={I18n.t('REFERRALS')} public={false}>
            <Table
                allowExport={true}
                exportTitle={I18n.t('EXPORT_REFERRALS')}
                loaderButtonExport={loaderButtonExport}
                exportOnPress={() => handleOnPress()}
                title={I18n.t('REFERRALS_OVERVIEW_TITLE')}
                showActions={true}
                allowNew={true}
                newTitle={I18n.t('ADD_NEW_REFERRAL')}
                type='referrals'
                loading={loader}
                filterOn={(type: string, val: string) => filterOn(type, val)}
                filterText={(val: string) => filterText(val)}
                columns={{
                    headings: [
                        {
                            Header: I18n.t('TABLE_REFERRAL_CODE'),
                            accessor: 'col0',
                            type: 'code',
                            Link: true,
                        },
                        {
                            Header: I18n.t('TABLE_REFERRAL_STATUS'),
                            accessor: 'col1',
                            type: 'status',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TABLE_REFERRAL_CUSTOMER'),
                            accessor: 'col2',
                            type: 'code',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TABLE_REFERRAL_DATE_EXPIRY'),
                            accessor: 'col3',
                            type: 'date',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TABLE_REFERRAL_DATE_UPDATED'),
                            accessor: 'col4',
                            type: 'date',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TABLE_REFERRAL_DATE_CREATED'),
                            accessor: 'col5',
                            type: 'date',
                            Link: false,
                        },
                    ],
                }}
                data={data}
                pageCount={pageCount}
                currentPage={currentPage}
                changePage={(page: number) => changePage(page)}
                confirmDelete={(id: number) => confirmDelete(id)}
            />
            <Snackbar ref={snackBar} />
        </Wrapper>
    );
};

export default Referrals;

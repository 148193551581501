import { useState, FC, useEffect } from 'react';
import Wrapper from '../../components/wrapper';
import I18n from '../../helpers/i18n';

import { useParams, useNavigate } from 'react-router-dom';
import Table from '../../components/tables/referral-detail';
import DirectusManager from '../../helpers/directusManager';
import * as Sentry from '@sentry/react';

const ReferralDetail: FC = (props) => {
    const navigate = useNavigate();
    const params = useParams<{ id: any }>();
    const [data, setData] = useState<any>({});

    useEffect(() => {
        reloadReferral();
    }, []);

    const reloadReferral = () => {
        let ref = DirectusManager.shared.directus.items('referrals');
        ref.readOne(params.id, { fields: ['*.*'] })
            .then((ref) => {
                setData(ref);
            })
            .catch((error) => {
                Sentry.captureException(error);
                console.log(error.message);
            });
    };

    const confirmDelete = (id: number) => {
        var ref = DirectusManager.shared.directus.items('referrals');
        ref.updateOne(id, { deleted: true })
            .then((data) => {
                returnMain();
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                console.log(error);
            });
    };

    const confirmArchive = (id: number) => {
        var ref = DirectusManager.shared.directus.items('referrals');
        ref.updateOne(id, { status: 5 })
            .then((data) => {
                returnMain();
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                console.log(error);
            });
    };

    const confirmClose = (id: number) => {
        var ref = DirectusManager.shared.directus.items('referrals');
        ref.updateOne(id, { status: 4 })
            .then((data) => {
                returnMain();
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                console.log(error);
            });
    };

    const returnMain = () => {
        navigate('/referrals');
    };

    return (
        <Wrapper title={I18n.t('REFERRAL DETAIL')} public={false}>
            <Table
                data={data}
                referral={true}
                confirmDelete={(id: number) => confirmDelete(id)}
                confirmArchive={(id: number) => confirmArchive(id)}
                confirmClose={(id: number) => confirmClose(id)}
                reloadReferral={() => reloadReferral()}
            />
        </Wrapper>
    );
};

export default ReferralDetail;

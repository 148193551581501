import React, { FC, useEffect, useState } from 'react';
import I18n from '../../../../helpers/i18n';
import Helpers from '../../../../helpers/functions';
import moment from 'moment';
import DirectusManager from '../../../../helpers/directusManager';
import * as Sentry from '@sentry/react';

const Validators = Helpers.instance;
interface Props {
    data: any;
    referral?: boolean;
    reloadReferral: () => void;
}

const GeneralInfo: FC<Props> = (props) => {
    const [customerName, setCustomerName] = useState('');

    useEffect(() => {
        loadCustomerData();
    }, [props.data.customer_id]);

    const getDate = (date: any) => {
        if (date == null) {
            return '-';
        }
        var parseDate = moment(date).format('DD-MM-YYYY');
        return parseDate;
    };

    const getStatus = (status: string) => {
        let st = '';
        if (status === 'pending') {
            st = I18n.t('STATUS_PENDING');
        }
        if (status === 'done') {
            st = I18n.t('STATUS_DONE');
        }
        if (status === 'confirmed') {
            st = I18n.t('STATUS_CONFIRMED');
        }
        if (status === 'renewal') {
            st = I18n.t('STATUS_RENEWAL');
        }
        return st;
    };

    const loadCustomerData = () => {
        if (props.data.customer_id !== undefined && props.data.customer_id !== null) {
            let ref = DirectusManager.shared.directus.items('customers');
            ref.readOne(props.data.customer_id.id, { fields: ['*'] })
                .then((d) => {
                    setCustomerName(d.name);
                })
                .catch((error) => {
                    Sentry.captureException(error);
                    console.log(error.message);
                });
        }
    };

    return (
        <div className='table-wrapper detail'>
            <div className='table-header filled d-flex justify-content-between align-items-center'>
                <span className='title'>{I18n.t('GENERAL_INFO')}</span>
            </div>
            <table className='table'>
                <thead></thead>
                <tbody>
                    <tr>
                        <td className='td-heading'>{I18n.t('TABLE_REFERRAL_ID')}</td>
                        <td>
                            <div className='d-flex align-items-center'>{props.data.id}</div>
                        </td>
                    </tr>
                    <tr>
                        <td className='td-heading'>{I18n.t('TABLE_REFERRAL_CODE')}</td>
                        <td>{props.data.code}</td>
                    </tr>
                    <tr>
                        <td className='td-heading'>{I18n.t('TABLE_STATUS')}</td>
                        <td>
                            <div className='d-flex align-items-center justify-content-between'>
                                <span className='status-text'>{getStatus(props.data.status)}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className='td-heading'>{I18n.t('TABLE_REFERRAL_COUPON_CODE')}</td>
                        <td>
                            <div className='d-flex align-items-center'>
                                {props.data.discount_code ? <td>{props.data.discount_code}</td> : <td>{'-'}</td>}
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td className='td-heading'>{I18n.t('TABLE_REFERRAL_CUSTOMER_NAME')}</td>
                        {customerName ? <td>{customerName}</td> : <td>{'-'}</td>}
                    </tr>
                    <tr>
                        <td className='td-heading'>{I18n.t('TABLE_CUSTOMER_INVOICE_ID')}</td>
                        <td>{props.data.invoice_id}</td>
                    </tr>
                    <tr>
                        <td className='td-heading'>{I18n.t('TABLE_DISCOUNT')}</td>
                        <td>{props.data.discount_amount + ' %'}</td>
                    </tr>
                    <tr>
                        <td className='td-heading'>{I18n.t('TABLE_PRODUCT_NAME')}</td>
                        {props.data.product_name ? <td>{props.data.product_name}</td> : <td>{'-'}</td>}
                    </tr>
                    <tr>
                        <td className='td-heading'>{I18n.t('TABLE_REFERRAL_DATE_EXPIRY')}</td>
                        <td>{getDate(props.data.expiry_date)}</td>
                    </tr>
                    <tr>
                        <td className='td-heading'>{I18n.t('TABLE_REFERRAL_DATE_CREATED')}</td>
                        <td>{getDate(props.data.date_created)}</td>
                    </tr>

                    <tr>
                        <td className='td-heading'>{I18n.t('TABLE_LAST_CHANGE')}</td>
                        <td>{getDate(props.data.date_updated)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
export default GeneralInfo;

import { useState, FC, useEffect, useRef, useContext } from 'react';
import Wrapper from '../../components/wrapper';
import I18n from '../../helpers/i18n';
import Snackbar from '../../components/snackbar';
import Table from '../../components/tables/referral-new';
import { useNavigate } from 'react-router-dom';
import DirectusManager from '../../helpers/directusManager';
import useDebounce from '../../hooks/use-debounce';
import Helpers from '../../helpers/functions';
import { UserContext } from '../../context/userContext';
import * as Sentry from '@sentry/react';
const Validators = Helpers.instance;

const NewReferral: FC = (props) => {
    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const snackBar = useRef();

    const [customers, setCustomers] = useState<any>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<any>(1);

    const [invoiceID, setInvoiceID] = useState('');
    const [errorInvoice, setErrorInvoice] = useState(false);
    const [errorInvoiceText, setErrorInvoiceText] = useState('');

    const [discount, setDiscount] = useState(0);
    const [discountError, setDiscountError] = useState(false);
    const [discountErrorText, setDiscountErrorText] = useState('');

    // const [maxDiscount, setMaxDiscount] = useState('');

    useEffect(() => {
        reloadData();
    }, []);

    // const debouncedDiscountTerm = useDebounce(discount, 600);
    // useEffect(() => {
    //     setDiscountError(false);
    //     setDiscountErrorText('');
    //     if (discount !== '' && maxDiscount !== '') {
    //         let valField = Validators.validateDiscount(discount, maxDiscount);
    //         setDiscountError(valField[0]);
    //         setDiscountErrorText(valField[1] + ' (max: ' + maxDiscount + '%)');
    //     }
    // }, [debouncedDiscountTerm]);

    const debouncedInvoiceTerm = useDebounce(invoiceID, 600);
    useEffect(() => {
        setErrorInvoice(false);
        setErrorInvoiceText('');
        if (invoiceID !== '') {
            let valField = Validators.validateInvoiceID(invoiceID);
            setErrorInvoice(valField[0]);
            setErrorInvoiceText(valField[1]);
        }
    }, [debouncedInvoiceTerm]);

    const reloadData = async () => {
        setLoader(true);
        var userCreated = userContext.user_id;
        let sortCustomers: any = ['name'];
        var customers = DirectusManager.shared.directus.items('customers');
        customers
            .readByQuery({
                limit: -1,
                sort: sortCustomers,
                filter: { status: 'active', user_created: userCreated, deleted: false },
            })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    let mapped = data.data
                        .map((x: any) => ({
                            name: x.name,
                            id: x.id,
                            date_created: new Date(x.date_created).getTime(),
                        }))
                        .sort((a: any, b: any) => b.date_created - a.date_created);
                    setSelectedCustomer(mapped[0].id);
                    setCustomers(mapped);
                }
            })
            .catch((error: any) => {
                console.log(error);
            });

        await getMaxDiscount(userCreated);
    };

    const saveReferral = async (ref: any) => {
        if (errorInvoice) return;
        if (invoiceID === '') {
            setErrorInvoice(true);
            setErrorInvoiceText('INVOICE IS REQUIRED');
            return;
        }

        var suppID = userContext.supplier_id;
        let referralData = {
            customer_id: selectedCustomer,
            status: 'pending',
            supplier_id: suppID,
            discount_amount: discount,
            invoice_id: invoiceID,
        };

        var referral = DirectusManager.shared.directus.items('referrals');
        referral
            .createOne(referralData)
            .then((data: any) => {
                if (data) {
                    navigate('/referrals', { state: 'new' });
                }
            })
            .catch((error: any) => {
                //Sentry.captureException(error);
                console.log(error);
            });
    };

    const getMaxDiscount = async (userCreated: string) => {
        var referral = await DirectusManager.shared.directus.items('suppliers');
        await referral
            .readByQuery({ filter: { user_id: userCreated } })
            .then((data: any) => {
                if (data) {
                    setDiscount(data.data[0].percentage_discount);
                }
            })
            .catch((error: any) => {
                //Sentry.captureException(error);
                console.log(error);
            });
    };

    return (
        <Wrapper title={I18n.t('TABLE_REFERRAL_NEW_REFERRAL')} public={false}>
            <Table
                title={I18n.t('NEW_REFERRAL')}
                type='referral'
                customers={customers}
                selectedCustomer={selectedCustomer}
                setSelectedCustomer={(id: number) => setSelectedCustomer(id)}
                discount={discount}
                setDiscount={(val: number) => setDiscount(val)}
                discountError={discountError}
                discountErrorMessage={discountErrorText}
                invoiceID={invoiceID}
                setInvoiceID={setInvoiceID}
                errorInvoice={errorInvoice}
                errorInvoiceMessage={errorInvoiceText}
                saveReferral={(ref) => saveReferral(ref)}
            />
            <Snackbar ref={snackBar} />
        </Wrapper>
    );
};

export default NewReferral;

import { useState, FC, useEffect, useRef, useContext } from 'react';
import Snackbar from '../../components/snackbar';
import Table from '../../components/tables/overview-table-full';
import Wrapper from '../../components/wrapper';
import I18n from '../../helpers/i18n';
import { useNavigate, useLocation } from 'react-router-dom';
import DirectusManager from '../../helpers/directusManager';
import moment from 'moment';
import { UserContext } from '../../context/userContext';
import * as Sentry from '@sentry/react';

const Customers: FC = (props) => {
    const userContext = useContext(UserContext);
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState<any>([]);
    const [pageCount, setPageCount] = useState<number>(1);
    const snackBar = useRef();
    const [activeFilter, setActiveFilter] = useState<any>(null);
    const [activeQuery, setActiveQuery] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const location = useLocation() as any;
    const navigate = useNavigate() as any;

    useEffect(() => {
        getData();

        if (location?.state == 'new') {
            navigate('', { state: [] });
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('NEW_CUSTOMER_ADDED'));
        }
    }, []);

    const confirmDelete = (id: number) => {
        var customers = DirectusManager.shared.directus.items('customers');
        customers
            .updateOne(id, { deleted: true })
            .then((data) => {
                setLoader(false);
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('CUSTOMER_DELETED'));
                getData();
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                setLoader(false);
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
            });
    };

    const changePage = (page: number) => {
        getData(activeFilter, activeQuery, page + 1);
        setCurrentPage(page + 1);
    };

    const getData = async (filters?: any, query?: string, page?: number) => {
        var supplier_user_id = userContext.user_id;

        // Get customers
        var filter: any = { deleted: false, user_created: supplier_user_id };
        var sort: any = ['-date_created'];

        const cst = await DirectusManager.shared.directus.items('customers');
        await cst
            .readByQuery({
                fields: ['*.*'],
                meta: 'filter_count',
                filter: filter,
                sort: sort,
                limit: 20,
                page: page ?? 1,
            })
            .then((custom) => {
                if (custom.data !== undefined && custom.data !== null) {
                    let mapped = custom.data.map((x: any) => {
                        return {
                            id: x.id,
                            col0: x.id,
                            col1: x.name ?? '',
                            col2: x.phone,
                            col3: getStatus(x.status),
                            col4: moment(x.date_created).format('DD-MM-YYYY'),
                        };
                    });
                    setData(mapped);
                    if (custom.meta?.filter_count !== undefined) {
                        var totalPages = Math.ceil(custom.meta?.filter_count / 20);
                        setPageCount(totalPages);
                    }
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                console.log(error);
            });
    };

    const getStatus = (status: string) => {
        let st = '';
        if (status === 'active') {
            st = I18n.t('STATUS_ACTIVE');
        } else {
            st = I18n.t('STATUS_INACTIVE');
        }
        return st;
    };

    return (
        <Wrapper title={I18n.t('CUSTOMERS')} public={false}>
            <Table
                title={I18n.t('CUSTOMERS_OVERVIEW_TITLE')}
                showActions={true}
                allowNew={true}
                newTitle={I18n.t('ADD_NEW_CUSTOMER')}
                type='customers'
                loading={loader}
                columns={{
                    headings: [
                        {
                            Header: I18n.t('TABLE_CUSTOMER_ID'),
                            accessor: 'col0',
                            type: 'id',
                            Link: true,
                        },
                        {
                            Header: I18n.t('TABLE_CUSTOMER_NAME'),
                            accessor: 'col1',
                            type: 'name',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TABLE_CUSTOMER_PHONE'),
                            accessor: 'col2',
                            type: 'phone',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TABLE_CUSTOMER_STATUS'),
                            accessor: 'col3',
                            type: 'status',
                            Link: false,
                        },
                        ,
                        {
                            Header: I18n.t('TABLE_CUSTOMER_DATE_CREATED'),
                            accessor: 'col4',
                            type: 'date',
                            Link: false,
                        },
                    ],
                }}
                data={data}
                pageCount={pageCount}
                currentPage={currentPage}
                changePage={(page: number) => changePage(page)}
                confirmDelete={(id: number) => confirmDelete(id)}
            />
            <Snackbar ref={snackBar} />
        </Wrapper>
    );
};

export default Customers;
